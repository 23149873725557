import * as React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout.js"
import Seo from "../components/seo.js"
import { ImgixGatsbyImage } from '@imgix/gatsby'

import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);


const IndexPage = ({ data }) => {
  return (
        <Layout>
          <Seo />
      <div>
        <div className="mainphAreabg">
          <div className="mainphArea container">
            <p className="catch">WordPressのセキュリティが心配なら</p>
            <h1><span>ヘッドレスCMS <br />制作・構築</span></h1>
            <p className="txt">
              セキュリティに強く、マルチデバイス対応も柔軟に。<br />
              大量のアクセスに強く、優れたパフォーマンスを実現するCMS。<br />
            </p>
          </div>{/* /mainphArea */}
        </div>{/* /mainphAreabg */}
        {/* こんなご要望 start */}
        <section>
          <div className="beigeBg">
            <div className="probArea container">
              <h2 className="h2Ttl">こんなご要望・<br class="spView" />お悩み解決します！</h2>
              <ul className="proList clearfix">
                <li className="secu"><p>1</p>WordPressは<br /><span>セキュリティ</span>が心配</li>
                <li className="spee"><p>2</p><span>表示速度</span>が遅いので<br />早くしたい</li>
                <li className="acce"><p>3</p><span>大量のアクセス</span>に<br />強くしたい</li>
                <li className="cost"><p>4</p><span>毎月のコスト</span>を<br />抑えたい</li>
                <li className="cms"><p>5</p>一部分だけ<br /><span>CMS化</span>したい</li>
                <li className="comm"><p>6</p>WEBアプリの<br /><span>共通化</span>をしたい</li>
              </ul>
            </div>{/* /probArea */}
          </div>{/* /beigeBg */}
        </section>
        {/* こんなご要望 end */}
        {/* お問い合わせ start */}
        <div className="containerS">
          <div className="contactArea containerS clearfix">
            <div className="conLt">
              <a href="tel:0364137735">03-6413-7735</a>
              <p>受付時間／9時～18時（土日祝 定休）</p>
            </div>{/* /conLt */}
            <div className="conRt">
              <p className="comm">お気軽にご相談・お問い合わせください</p><br />
              <Link to={`https://www.synergate.co.jp/contact/`} className="nmlBtn contact" target="_blank">お見積もり・お問い合わせ</Link>
            </div>{/* /conRt */}
          </div>{/* /contactArea */}
        </div>{/* /containerS */}
        {/* お問い合わせ end */}
        {/* ヘッドレスCMSなら start */}
        <section>
          <div className="pointArea containerS">
            <h2><span>ヘッドレスCMSなら</span></h2>
            <dl className="pointLt clearfix">
              <dt>
                <p className="point">POINT<span>1</span></p>
                <h3><span>セキュリティに強い</span></h3>
                <p className="txt">ヘッドレスCMSはフロントエンドとバックエンド（管理画面）が完全に分離しているCMSになります。フロント側は、ヘッドレスCMS から情報を取得して表示するだけですので、管理画面などがなくなる為、ハッカーに攻撃されにくくなります。また、クラウドサービスを使うことで、脆弱性対応やアップデートも自動で行われますので、常にセキュリティも最新の状態に保たれます。</p>
              </dt>
              <dd><img src="/images/point01.png" width="600" height="600" alt="セキュリティに強い" loading="lazy" /></dd>
            </dl>
            <dl className="pointRt clearfix">
              <dt>
                <p className="point">POINT<span>2</span></p>
                <h3><span>表示速度が速い</span></h3>
                <p className="txt">ヘッドレスCMSはCDNを使ってコンテンツが配信されます。世界各地でキャッシュされたデータが最適化された状態で参照されるようになり、優れたパフォーマンスと大量のアクセスにも強いWEBサイトを公開することが可能になります。Jamstack構成にすることでブログやお知らせなどの記事も静的に生成されるようになり、さらなる高速化が可能です。</p>
              </dt>
              <dd><img src="/images/point02.png" width="600" height="600" alt="表示速度が速い" loading="lazy" /></dd>
            </dl>
            <dl className="pointLt clearfix">
              <dt>
                <p className="point">POINT<span>3</span></p>
                <h3><span>大量のアクセスに強く<br class="spView" />コストも削減</span></h3>
                <p className="txt">通常のCMSの場合、動的にコンテンツが表示される為、アクセスがある度にサーバー側で処理する必要があり、サーバーに負荷がかかります。ヘッドレスCMSでJamstack構成にすることで、静的にページが生成され、さらにCDNによりキャッシュされて負荷が分散される為、大量のアクセスにも強くなり、冗長化の負担も軽減されることでコストを削減することが出来ます。</p>
              </dt>
              <dd><img src="/images/point03.png" width="600" height="600" alt="大量のアクセスにも強くコスト削減" loading="lazy" /></dd>
            </dl>
            <dl className="pointRt clearfix">
              <dt>
                <p className="point">POINT<span>4</span></p>
                <h3><span>部分的にCMS化が可能</span></h3>
                <p className="txt">ヘッドレスCMSは、管理画面が独立しており、コンテンツをAPIベースで取得できます。その為、すでにHTMLで公開されているWEBサイトでも、「お知らせ」や「実績紹介」などのコンテンツを部分的にCMS化することが可能で、さらに公開サーバー側にCMSのシステムやDBを用意する必要がないので、気軽にCMSを導入することが可能になります。</p>
              </dt>
              <dd><img src="/images/point04.png" width="600" height="600" alt="部分的にCMS化が可能" loading="lazy" /></dd>
            </dl>
            <dl className="pointLt clearfix">
              <dt>
                <p className="point">POINT<span>5</span></p>
                <h3><span>マルチデバイス対応</span></h3>
                <p className="txt">ヘッドレスCMSはAPIベースで表示したい箇所に合わせて柔軟に対応できる為、WEBサイトで表示しているお知らせやブログなどの記事を、AndroidやiOSアプリと共通化することが可能です。さらにWEBサイトのリニューアル時には、バックエンドはそのままで、フロントエンドだけをCMSの種類に依存せずに、容易に変更することができます。</p>
              </dt>
              <dd><img src="/images/point05.png" width="600" height="600" alt="マルチデバイス対応" loading="lazy" /></dd>
            </dl>
          </div>{/* /pointArea */}
        </section>
        {/* ヘッドレスCMSなら end */}
        {/* Jamstack start */}
        <section>
          <div className="blueBg">
            <div className="jamArea container">
              <h2 className="h2Ttl txtbk bdblu">ヘッドレスCMSと<br class="spView" />相性の良い<br />Jamstackとは？</h2>
              <dl className="pointLt clearfix" data-animate="fadeInUp">
                <dt>
                  <p className="txt pb20">Jamstack（ジャムスタック）とは「JavaScript」「API」「Markup」の3つの技術を組み合わせて作られたWEBサイトの新しい開発手法で、サーバーレスでサイトを運用する構成になります。予めヘッドレスCMSなどから配信された情報を取得し、静的ページを生成し、CDNから配信されることで、優れたレスポンスとパフォーマンスを実現させることができます。</p>
                  <p className="txt">また、アプリの運用でヘッドレスCMSを使う為、WEBサイトに関してはヘッドレスCMSから情報を取得し、Jamstackで運用したい、という用途でも使われます。</p>
                </dt>
                <dd><img src="/images/jamstack_ph.jpg" width="600" height="400" alt="セキュリティに強い" loading="lazy" /></dd>
              </dl>
              <div className="thisJam">
                <p>この「ヘッドレスCMS 特設サイト」もJamstack構成で制作されています。SSGには「Gatsby」、ホスティングサービスには「Cloudflare Pages」ヘッドレスCMSには「microCMS」を利用した、Jamstack構成のサイトとなっています。是非、ページ間の遷移の速さを実感してみてください。</p>
                <p className="alct pt20"><img src="/images/thisjam_ph.png" width="521" height="105" alt="セキュリティに強い" loading="lazy" className="maxImg" /></p>
              </div>
            </div>{/* /jamArea */}
          </div>{/* /blueBg */}
        </section>
        {/* Jamstack end */}
        {/* 比較 start */}
        <section>
          <div className="compArea containerS">
            <h2 className="h2Ttl">CMSの比較</h2>
            <p>ヘッドレスCMSは、CMS機能に特化している為、機能追加は弱い面があります。WordPressに関しましては、無料で利用できて世界で一番使われているCMSだけあり、オールマイティな使いやすさが特長です。オリジナルCMSは、お客様の使い方に合わせて自由にカスタマイズが出来る分、導入コストがやや高めになってしまうことがネックとなりそうです。</p>
            <div className="pb10">
              <table className="ecTb">
                <tbody><tr>
                    <th className="gr">&nbsp;</th>
                    <th>ヘッドレスCMS</th>
                    <th>WordPress</th>
                    <th>オリジナルCMS</th>
                  </tr>
                  <tr>
                    <th className="gr">セキュリティ</th>
                    <td className="good">◎</td>
                    <td>△</td>
                    <td className="good">○</td>
                  </tr>
                  <tr>
                    <th className="gr">導入コスト</th>
                    <td className="good">○</td>
                    <td className="good">○</td>
                    <td>△</td>
                  </tr>
                  <tr>
                    <th className="gr">マルチデバイス</th>
                    <td className="good">◎</td>
                    <td className="good">○</td>
                    <td className="good">○</td>
                  </tr>
                  <tr>
                    <th className="gr">機能追加</th>
                    <td>△</td>
                    <td className="good">○</td>
                    <td className="good">◎</td>
                  </tr>
                </tbody></table>
            </div>
          </div>{/* /compArea */}
        </section>
        {/* 比較 end */}
        {/* ブログ start */}
        <section>
          <div className="beigeBg">
            <div className="blogArea container">
              <h2 className="h2Ttl">ヘッドレスCMSブログ</h2>
              <ul className="blogList slide clearfix">
              {data.allMicrocmsBlog.edges.map(({ node }) => (
              <li>
                <Link to={`/blog/${node.slug}/`}>
                  <article className="post" key={node.id}>
                    <div className="ph"><time>{dayjs.utc(node.publishDate).tz('Asia/Tokyo').format('YYYY年M月D日')}</time>

                      <ImgixGatsbyImage
                        src={node.eyecatch.url}
                        imgixParams={{ auto: ["format", "compress"] }}
                        layout="constrained"
                        width={573}
                        sourceWidth={node.eyecatch.width}
                        sourceHeight={node.eyecatch.height}
                        style={{ height: "100%" }}
                        />

                      </div>
                      <h3>{node.title}</h3>
                    </article>
                  </Link>
                </li>
                ))}
              </ul>
            </div>{/* /brogArea */}
          </div>{/* /beigeArea */}
        </section>
        {/* ブログ end */}
        {/* お問い合わせ start */}
<div class="pt60">
        <div className="containerS">
          <div className="contactArea containerS clearfix">
            <div className="conLt">
              <a href="tel:0364137735">03-6413-7735</a>
              <p>受付時間／9時～18時（土日祝 定休）</p>
            </div>{/* /conLt */}
            <div className="conRt">
              <p className="comm">お気軽にご相談・お問い合わせください</p><br />
              <Link to={`https://www.synergate.co.jp/contact/`} className="nmlBtn contact" target="_blank">お見積もり・お問い合わせ</Link>
            </div>{/* /conRt */}
          </div>{/* /contactArea */}
        </div>{/* /containerS */}
        {/* お問い合わせ end */}
      </div>
</div>

        </Layout>
  )
}


export const query = graphql`
query {
  allMicrocmsBlog(
    sort: { order: DESC, fields: publishDate },
    skip: 0,
    limit: 3
  ) {
    edges {
      node {
        title
        id
        slug
        eyecatch {
          height
          url
          width
        }
        publishDate
      }
    }
  }
}`

export default IndexPage
